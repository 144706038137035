import { Button, Skeleton, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { PiLightningFill } from "react-icons/pi";
import { useAssistants } from "../../../state/assistants";
import ToastMessage from "../../ToastMessage";
import { Assistant } from "../../../gen-ts/ai/assistants/v0/assistant_pb";


interface Props {
  selectionPath: string[];
  assistant: Assistant;
  onSelect: (path: string[]) => void;
  nestedLevel: number;
}
const SubAssistantsList: React.FC<Props> = ({ assistant, onSelect, selectionPath, nestedLevel }) => {
  const assistantsState = useAssistants();
  const toast = useToast();

  const selectedSubAssistantId = selectionPath.length >= nestedLevel + 1 ? selectionPath[ nestedLevel ] : null;


  useEffect(() => {
    if (!assistant) {
      return;
    }

    for (const subAssistant of assistant.subAssistants) {
      // if (!selectedSubAssistant) {
      //   setSelectedSubAssistant(subAssistant);
      // }
      assistantsState.loadById({
        assistantId: subAssistant,
        onError: () => {
          toast({
            position: 'top-right',
            render: (props) => {
              return (
                <ToastMessage
                  message={`Assistant: ${subAssistant} not found.`}
                  status="error"
                  isCLosable={props.isClosable}
                  onClose={props.onClose}
                />
              );
            },
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }

    //eslint-disable-next-line
  }, [ assistant ])

  if (!assistant) {
    return null;
  }

  return (
    <VStack sx={{ paddingLeft: `${nestedLevel * 20}px`, }}>
      {
        assistant.subAssistants.map((subAssistantId) => {
          const isSelected = selectedSubAssistantId === subAssistantId;
          const subAssistant = assistantsState.assistants[ subAssistantId ];
          const hasSubAssistants = subAssistant?.subAssistants.length > 0;
          return (
            <VStack key={subAssistantId} sx={{ width: '100%', minWidth: 200, }} spacing={hasSubAssistants ? 2 : 0}>
              {
                subAssistant ? (
                  <Button
                    leftIcon={<PiLightningFill />}
                    sx={{
                      width: '100%',
                    }}
                    variant={isSelected ? "solid" : "ghost"}
                    justifyContent="start"
                    isTruncated
                    display="block"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="clip"
                    textAlign="left"
                    m={0}
                    height="40px"
                    lineHeight="40px"
                    borderRadius={5}
                    maxWidth="100%"
                    onClick={() => {
                      const prePath = selectionPath.slice(0, nestedLevel);
                      onSelect([
                        ...prePath,
                        subAssistantId,
                      ]);
                    }}
                  >
                    {subAssistant.displayName}
                  </Button>
                ) : (
                  <Skeleton
                    width="100%"
                    height="35px"
                    sx={{maxHeight: '35px', borderRadius: 5}}
                  />
                )
              }

              {
                isSelected && (
                  <SubAssistantsList
                    selectionPath={[
                      ...selectionPath,
                      subAssistantId,
                    ]}
                    assistant={subAssistant}
                    onSelect={onSelect}
                    nestedLevel={nestedLevel + 1}
                  />
                )
              }
            </VStack>
          );
        })
      }
    </VStack>
  );
};

export default SubAssistantsList;