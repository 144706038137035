import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useClipboard,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { ChatInfo } from "../../types";
import { Message, Role } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { MdAttachment, MdContentCopy, MdImage, MdLanguage } from "react-icons/md";
import { CgDebug } from "react-icons/cg";
import ReactMarkdown from "react-markdown";
import { useChats } from "../../state/chats";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ToastMessage from "../ToastMessage";
import { useDebugDialog } from "../DebugDialogProvider";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";


interface ChatMessagesProps {
  chat: ChatInfo | null;
  onUserScrollUpdate: (isUserScrolled: boolean) => void;
}

const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>(({ chat, onUserScrollUpdate }, ref) => {

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [ currMessageSources, setCurrMessageSources ] = useState<string | null>(null);

  const showSources = (runId: string) => {
    setCurrMessageSources(runId);
  }

  const hideSources = () => {
    setCurrMessageSources(null);
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      // console.log(scrollTop + clientHeight, scrollHeight);
      if (scrollHeight - (scrollTop + clientHeight) < 20) {
        onUserScrollUpdate(false);
      } else {
        onUserScrollUpdate(true);
      }
    }
  };

  return (
    <>
      <VStack height="100%" width="100%" overflow="scroll" onScroll={handleScroll} ref={containerRef}>
        <VStack maxWidth="1000px" width="100%" spacing={6}>
          {
            chat?.messages.map((message, index) => {


              if (message.role === Role.ASSISTANT) {
                const streaming = chat.streamingResponse && index === chat.messages.length - 1 ? true : false;
                return <AssistantMessage
                  key={index}
                  message={message as any}
                  streamRunning={streaming}
                  onShowSources={showSources}
                />;
              }
              else {
                return <UserMessage key={index} message={message as any} />;
              }
            })
          }
        </VStack>
        <div ref={ref} />
      </VStack>
      <MessageSources
        isOpen={Boolean(currMessageSources)}
        onClose={hideSources}
        message={chat?.messages.find(m => m.runId && m.runId === currMessageSources) as any}
      />
    </>
  );
});



export default ChatMessages;


const UserMessage: React.FC<{ message: Message }> = ({ message }) => {
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <>
      <VStack width="100%" spacing="3px">
        <MessageFiles message={message} />
        <HStack
          justify="end"
          bgColor={bgColor}
          borderRadius="20px"
          alignSelf="end"
          px={4}
          py={2}
        >
          <Box
            maxWidth={600}
          >
            <MessageContent message={message} />
          </Box>
        </HStack>
      </VStack>
    </>
  );
}

interface AssistantMessageProps {
  message: Message;
  streamRunning: boolean;
  onShowSources: (runId: string) => void;
}

const getMessageCopyText = (message: Message) => {
  return message.content.map(c => c.content.value).join('\n');
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({ message, streamRunning, onShowSources }) => {
  const { onCopy } = useClipboard(getMessageCopyText(message));
  const toast = useToast();
  const chatsState = useChats();
  const debugDialog = useDebugDialog();

  const handleOnCopy = () => {
    onCopy();
    toast({
      position: 'top-right',
      render: (props) => {
        return (
          <ToastMessage
            message="Copied to clipboard."
            status="success"
            isCLosable={props.isClosable}
            onClose={props.onClose}
          />
        );
      },
      duration: 1000,
      isClosable: true,
    });
  }

  const run = chatsState.runs[ message.runId ];

  return (
    <HStack
      justify="start"
      alignSelf="start"
      // maxWidth={600}
      width="100%"
      align="start"
    >
      <Avatar name='System Message' src='/logo512.png' size="sm" />
      <VStack align="start">
        <MessageContent message={message} />

        <HStack justify="start" spacing={3} visibility={streamRunning ? 'hidden' : 'visible'}>
          <Button onClick={handleOnCopy} size="xs" variant="ghost" leftIcon={<Icon as={MdContentCopy} fontSize="13px" />}>
            Copy
          </Button>
          <Button
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={MdLanguage}
              fontSize="13px" />}
            onClick={() => {
              onShowSources(message.runId);
            }}
          >
            Sources {
              run && (
                (`(${run.contextDocuments.length})`)
              )
            }
          </Button>

          <Button
            onClick={() => {
              debugDialog.openDialog({
                'Run ID': message.runId,
              });
            }}
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={CgDebug} fontSize="13px" />}
          >
            Debug
          </Button>
        </HStack>
      </VStack>
    </HStack>
  );
}


interface MessageSourcesProps {
  message?: Message;
  isOpen: boolean;
  onClose: () => void;
}
const MessageSources: React.FC<MessageSourcesProps> = ({ message, isOpen, onClose }) => {
  const chats = useChats();

  useEffect(() => {
    if (isOpen && message) {
      chats.loadRunById(message.runId);
    }

    //eslint-disable-next-line
  }, [ isOpen ]);

  const isLoading = message ? chats.runLoading[ message.runId ] : false;
  const run = message ? chats.runs[ message.runId ] : null;

  const iconBgColor = useColorModeValue("gray.100", "gray.800");


  const documents = run?.contextDocuments || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sources</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack pb={4}>
            {
              isLoading && (
                <>
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                </>
              )
            }
            {
              !isLoading && run?.contextDocuments.length === 0 && (
                <Text>No sources for this message.</Text>
              )
            }
            {
              !isLoading && documents.map((doc, index) => (
                <HStack width="100%" key={index}>
                  <Flex
                    px={3}
                    py={2}
                    borderRadius={5}
                    bgColor={iconBgColor}
                    height="100%"
                  >
                    <Icon
                      color="gray.500"
                      as={MdLanguage}
                    />
                  </Flex>
                  <Link
                    href={doc.url}
                    isExternal
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {doc.displayName ? doc.displayName : doc.filename} {doc.trailer ? `(${doc.trailer})` : ''}
                  </Link>
                  <ExternalLinkIcon mx="2px" mb="3px" />
                </HStack>
              ))
            }
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}



const MessageContent: React.FC<{ message: Message }> = ({ message }) => {

  const children: ReactNode[] = [];

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'text') {
      continue;
    }

    if (content.content.case === 'text') {
      if (message.role === Role.ASSISTANT) {
        // console.log('markdown', content.content.value);
        // content.content.value = content.content.value
        //   .replace(/^\s{2}\* /gm, '\t\t* ')
        //   .replace(/^\s{2}- /gm, '\t\t- ')
        //   .replace(/^(- )/gm, '\t- ')
        //   .replace(/^(\* )/gm, '\t* ')
        //   ;
        // console.log('markdown', content.content.value);
        children.push(
          <MessageText key={i} text={content.content.value} />
        );
      }
      else {
        children.push(
          <VStack key={i} spacing={0} align="start">
            {
              content.content.value.split('\n').map((text, index) => (
                <Text key={index}>{text}</Text>
              ))
            }
          </VStack>
        );
      }
    }
    // else if (content.content.case === 'image') {
    //   if (content.content.value.source.case === 'url') {
    //     children.push(<Image key={i} src={content.content.value.source.value} width="300px" />);
    //   }
    //   else if (content.content.value.source.case === 'data') {
    //     const decoder = new TextDecoder('utf8');
    //     const b64encoded = btoa(decoder.decode(content.content.value.source.value.data));
    //     children.push(<Image key={i} src={b64encoded} width="300px" />);
    //   }
    // }
    // else {
    //   children.push(
    //     <Text key={`${i}-1`}>Unsupoported message type: {content.content.case}</Text>
    //   );
    // }

  }

  return (
    <VStack align="start">
      {children}
    </VStack>
  );
}

const MessageFiles: React.FC<{ message: Message }> = ({ message }) => {

  var chats = useChats();
  useEffect(() => {
    for (let i = 0; i < message.content.length; i++) {
      const content = message.content[ i ];

      if (content.content.case !== 'file') {
        continue;
      }

      chats.loadFile(content.content.value.source.value!);
    }
    //eslint-disable-next-line
  }, []);


  const files: ReactNode[] = [];
  const images: ReactNode[] = [];
  const fileBgColor = useColorModeValue('gray.50', 'gray.700');
  const fileBorderColor = useColorModeValue('gray.300', 'gray.600');

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'file') {
      continue;
    }

    const ref = content.content.value.source.value;
    const fileInfo = chats.files[ ref ?? '' ];
    const fileLoading = chats.fileLoading[ ref ?? '' ];


    if (ref && fileInfo && !fileLoading) {
      if (fileInfo.contentType.startsWith('image1111/')) {
        images.push(
          <Link target="_blank" href={fileInfo.url}>
            <Box width="100px" height="100px">
              <Image
                src={fileInfo.url}
                alt={fileInfo.fileName}
                width="100px"
                height="100px"
                fit="cover"
                borderRadius={10}
              />
            </Box>
          </Link>
        );
      }
      else {
        files.push(
          <HStack
            bgColor={fileBgColor}
            borderColor={fileBorderColor}
            borderWidth="1px"
            px={2}
            py={1}
            borderRadius={10}
            maxWidth="500px"
            fontSize="14px"
          >
            <Icon
              as={
                fileInfo.contentType.startsWith('image') ? MdImage : MdAttachment
              }
            />
            <Link
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              target="_blank"
              title={fileInfo.fileName}
              href={fileInfo.url}
            >
              {fileInfo.fileName}
            </Link>
          </HStack>
        );
      }
    }
    else {
      files.push(
        <Skeleton width="200px" height="31px" />
      )
    }
  }



  return (
    <>
      <Wrap justify="end" width="100%">
        {
          images.map((child, index) => (
            <WrapItem key={index}>
              {child}
            </WrapItem>
          ))
        }
      </Wrap>
      <VStack width="100%" align="end" spacing="3px">
        {
          files.map((child, index) => (
            <Box key={index}>
              {child}
            </Box>
          ))
        }
      </VStack>
    </>
  );
}


const MessageText: React.FC<{ text: string }> = ({ text }) => {
  const boxBgColor = useColorModeValue('gray.100', 'gray.700');
  return (
    <Box
      overflowY="hidden"
      overflowX="auto"
      // maxWidth={900}
      width="100%"
    >
      <ReactMarkdown
        rehypePlugins={[ rehypeRaw, rehypeSanitize ]}
        skipHtml={false}
        components={{
          code({ node, className, children, ...props }) {
            const isBlockCode = className !== undefined;
            return isBlockCode ? (
              // Code block
              <Box
                as="pre"
                bg={boxBgColor}
                p={2}
                borderRadius="md"
                overflow="auto"
                // maxWidth={900}
                width="100%"
              >
                <code className={className} {...props}>
                  {children}
                </code>
              </Box>
            ) : (
              // Inline code
              <Box
                as="code"
                bg={boxBgColor}
                p="0.2em"
                borderRadius="md"
                // maxWidth={900}
                width="100%"
                {...props}
              >
                {children}
              </Box>
            );
          },
          ul: ({ children }) => <ul style={{ marginLeft: '20px' }}>{children}</ul>,
          ol: ({ children }) => <ol style={{ marginLeft: '20px' }}>{children}</ol>,
          li: ({ children }) => <li style={{ marginLeft: '20px' }}>{children}</li>,
        }}
      >
        {text}
      </ReactMarkdown>
    </Box>
  );
}